.Modal {
	position: fixed;
	z-index: 500;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px black;
	padding: 16px;
	box-sizing: border-box;
	transition: all 0.3s ease-out;
}
