/**
 *
 */
@use "../../styles/questionnaire/themes/theme" as t;

.spinner {
  margin: auto;
  width: fit-content;
}

.container {
  min-height: 100vh;
  padding-top: 50px;
  width: 90%;

  .no_margin_top {
    margin-top: 0;
  }

  .welcome_message {
    bottom: 60%;
    position: absolute;
    text-align: center;
  }

  label {
    background-color: t.$bg-white;
    padding-left: 5px;
    padding-right: 5px;
  }

  .contact_form {
    .formGrid {
      display: grid;
      grid-gap: 20px;

      @media #{t.$small-up-mq} {
        grid-template-columns: 1fr 1fr;
      }
    }

    .submit {
      margin: 50px auto;
      padding-left: 100px;
      padding-right: 100px;

      @media #{t.$small-up-mq} {
        margin: 50px 0;
      }
    }
  }

  a {
    text-decoration: underline;
  }
  p {
    display: block;
    margin-block: 1em;
    margin-inline: 0;
  }
}
