@import './../../styles/colors';
.term {
  color: $grey-dark-1;
}
.termsBox {
  background-color: #f7f7f7;
  height: 250px;
  overflow-y: auto;
  overflow-x: clip;
  padding: 10px;
}
.tooltipFitContent {
  padding-top: 10px;
  padding-bottom: 10px;
}
.formcontrol {
  align-items: flex-start;
}
.label {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.bang {
  color: $cta-error;
  justify-self: start;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bang1 {
  color: $cta-error;
  justify-self: start;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
.icon {
  height: 17px;
  padding-right: 8px;
}
