/**
 * Chevrolet Color Palette
 */

// base
$primary: #0079bf;
$secondary: #373637;
$white: #fff;
$black: #000;

// blue shades
$primary-light: #0693e4;

// grey shades
$grey-dark-1: #272a2f;
$grey-dark-2: #41474d;
$grey-dark-3: #878687;
$grey-dark-4: #9b9a9b;

$grey-light-1: #f7f7f7;
$grey-light-2: #e1e1e1;
$grey-light-3: #d7d7d7;
$grey-light-4: #c3c3c3;

// typography
$type-primary: #373637;
$type-secondary: #5f5e5f;
$type-disabled: #afafaf;
$type-white: #fff;

// dividers & borders
$body-divider: #d7d7d7;
$input-border: #5f5e5f;
$input-border-hover: #373637;
$border-white: #fff;

// actions
$cta-primary: $primary;
$cta-primary-light: $primary-light;
$cta-primary-disabled: #b2cde8;
$cta-secondary: $secondary;
$cta-link: $primary;
$cta-link-light: $primary-light;
$cta-info: #5f5e5f;
$cta-success: #4cae29;
$cta-warning: #ff8139;
$cta-error: #f14028;

// backgrounds & overlays
$bg-dark: #202020;
$bg-light: #f7f7f7;
$bg-white: #fff;
$overlay-dark: #0006;
$overlay-light: #fff6;

// pastel background and foreground
$pastel-bg-error: #ffe4e4;
$pastel-bg-warning: #fefcbf;
$pastel-bg-success: #d1ffe0;
$pastel-bg-info: #dce6f3;
$pastel-bg-accent: #f2e7ff;

$pastel-fg-error: #ad1e1e;
$pastel-fg-warning: #b7791f;
$pastel-fg-success: #22744b;
$pastel-fg-info: #003f80;
$pastel-fg-accent: #6b46c1;
