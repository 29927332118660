/**
 * Cadillac Color Palette
 */

// base
$black: #000;
$primary: #282828;
$secondary: #fcfcfc;
$white: #fff;

// grey shades
$grey-3: #787878;

$grey-dark-1: #0d0d0d;
$grey-dark-2: #282828;
$grey-dark-3: #505050;
$grey-dark-4: #787878;
$grey-darker: #252f32;

$grey-light-1: #fcfcfc;
$grey-light-2: #f2f2f2;
$grey-light-3: #d2d2d2;
$grey-light-4: #a0a0a0;
$grey-tint-70: #666d6f;

//reds
$dark-red: #a00021;
$light-red: #fa335e;
$medium-red: #c8002b;
$red: #fa0037;

//blues
$blue: #2c28e8;
$dark-blue: #171473;
$light-blue: #5754ed;
$medium-blue: #211fab;

//golds
$dark-gold: #c8800d;
$gold: #f3c846;
$light-gold: #f5d98f;
$medium-gold: #e8b01e;

// typography
$type-primary: $primary;
$type-secondary: $secondary;
$type-disabled: $grey-light-4;
$type-gold: $gold;
$type-white: $white;

// dividers & borders
$body-divider: $grey-light-3;
$input-border: $grey-dark-3;
$input-border-hover: $grey-dark-2;
$border-white: $white;

// actions
$cta-primary: $primary;
$cta-primary-disabled: $grey-light-3;
$cta-secondary: $secondary;
$cta-secondary-disabled: $grey-dark-3;
$cta-link: $primary;
$cta-link-light: $primary;
$cta-info: $grey-dark-4;
$cta-success: #0a7305;
$cta-warning: #ff8139;
$cta-error: #b50000;

// backgrounds & overlays
$bg-dark: $grey-dark-1;
$bg-light: $grey-light-1;
$bg-white: $white;
$overlay-dark: $black;
$overlay-light: $grey-dark-4;
