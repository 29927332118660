$font-family-cadillac-gothic: 'Cadillac Gothic', sans-serif;
$font-family-cadillac-gothic-wide: 'Cadillac Gothic Wide', sans-serif;

//All Cadillac fonts have a base of 16px
$base-font: 16px;

// Base font url for font files
$base-font-url-path: '/fonts/';

$font-weight-lighter: 100;
$font-weight-light: 200;
$font-weight-book: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-ultra: 800;
$font-weight-xbold: 900;

$font-list: (
	'Cadillac Gothic': (
		('CadillacGothic-Bold', normal, $font-weight-book),
		('CadillacGothic-Medium', normal, $font-weight-medium),
		('CadillacGothic-Regular', normal, $font-weight-lighter),
	),
	'Cadillac Gothic Wide': (
		('CadillacGothic-WideBold', normal, $font-weight-demi),
		('CadillacGothic-WideMedium', normal, $font-weight-medium),
		('CadillacGothic-WideRegular', normal, $font-weight-normal),
	),
);
