/**
 * Typography mixin to create font classes
 */
@mixin typography(
	$color,
	$font-family,
	$font-size,
	$font-weight,
	$letter-spacing,
	$line-height,
	$text-transform
) {
	color: $color;
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	line-height: $line-height;
	text-transform: $text-transform;
}
