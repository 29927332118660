@font-face {
    font-family: 'LouisGlobal2Bold';
    src: local('LouisGlobal2Bold'), url(./fonts/LouisGlobal2-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2BoldItalic';
    src: local('LouisGlobal2BoldItalic'), url(./fonts/LouisGlobal2-BoldItalic.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2CondensedDemi';
    src: local('LouisGlobal2CondensedDemi'), url(./fonts/LouisGlobal2-CondensedDemi.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2CondensedReg';
    src: local('LouisGlobal2CondensedReg'), url(./fonts/LouisGlobal2-CondensedReg.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2Heavy';
    src: local('LouisGlobal2Heavy'), url(./fonts/LouisGlobal2-Heavy.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2HeavyItalic';
    src: local('LouisGlobal2HeavyItalic'), url(./fonts/LouisGlobal2-HeavyItalic.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2Italic';
    src: local('LouisGlobal2Italic'), url(./fonts/LouisGlobal2-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'LouisGlobal2Reg';
    src: local('LouisGlobal2Reg'), url(./fonts/LouisGlobal2-Reg.otf) format('opentype');
}

@font-face {
    font-family: 'RobotoReg';
    src: local('RobotoReg'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'CadillacGothicBold';
    src: local('CadillacGothicBold'), url(./fonts/CadillacGothic-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'CadillacGothicMedium';
    src: local('CadillacGothicMedium'), url(./fonts/CadillacGothic-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'CadillacGothicRegular';
    src: local('CadillacGothicRegular'), url(./fonts/CadillacGothic-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'CadillacGothicWideBold';
    src: local('CadillacGothicWideBold'), url(./fonts/CadillacGothic-WideBold.otf) format('opentype');
}

@font-face {
    font-family: 'CadillacGothicWideMedium';
    src: local('CadillacGothicWideMedium'), url(./fonts/CadillacGothic-WideMedium.otf) format('opentype');
}

@font-face {
    font-family: 'CadillacGothicWideRegular';
    src: local('CadillacGothicWideRegular'), url(./fonts/CadillacGothic-WideRegular.otf) format('opentype');
}