/**
 * Button mixin to create button classes
 */
@mixin button(
	$bg-color,
	$border,
	$border-radius,
	$color,
	$font-family,
	$font-size,
	$font-weight,
	$letter-spacing,
	$line-height,
	$padding
) {
	@extend %button-base;

	background-color: $bg-color;
	border: $border;
	border-radius: $border-radius;
	color: $color;
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	line-height: $line-height;
	padding: $padding;
	transition-duration: 150ms;
	transition-property: background-color, border, color, opacity;
	transition-timing-function: ease-in;
}

%button-base {
	align-items: center;
	appearance: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0.5rem 2rem;
	text-align: center;
	text-decoration: none;
	width: 100%;
}

// todo: remove!!!
@mixin primary-button(
	$bg-color,
	$border,
	$color,
	$font-family,
	$font-size,
	$font-weight,
	$letter-spacing,
	$line-height,
	$padding,
	$hover-opacity
) {
	@extend %button-base;

	background-color: $bg-color;
	border: $border;
	color: $color;
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	line-height: $line-height;
	padding: $padding;
	transition-duration: 150ms;
	transition-property: background-color, border, color, opacity;
	transition-timing-function: ease-in;

	width: 100%;

	&:hover,
	&:active,
	&:focus {
		opacity: $hover-opacity;
	}
}

@mixin secondary-button(
	$bg-color,
	$border,
	$color,
	$font-family,
	$font-size,
	$font-weight,
	$letter-spacing,
	$line-height,
	$padding,
	$hover-opacity
) {
	@extend %button-base;

	background-color: $bg-color;
	border: $border;
	color: $color;
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	line-height: $line-height;
	padding: $padding;
	transition-duration: 150ms;
	transition-property: background-color, border, color, opacity;
	transition-timing-function: ease-in;
	width: 100%;

	&:hover,
	&:active,
	&:focus {
		opacity: $hover-opacity;
	}
}
