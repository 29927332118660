@use "./partials/button" as b;
@use "colors" as c;
@use "fonts" as f;
@use "breakpoints" as bp;

.button {
  @include b.button(
    null,
    2px solid transparent,
    0,
    c.$type-primary,
    f.$font-family-cadillac-gothic,
    14px,
    f.$font-weight-light,
    1.6px,
    22px,
    13px 26px
  );

  &.button-primary,
  &.button-primary-dark {
    background-color: c.$cta-primary;
    border-color: c.$cta-primary;
    color: c.$type-secondary;

    &:not(:disabled) {
      &:hover {
        background-color: c.$cta-secondary;
        border-color: c.$cta-primary;
        color: white;
      }
    }

    &.transparent {
      background-color: transparent;
      border-color: c.$cta-primary;
      color: c.$type-primary;

      &:not(:disabled) {
        &:hover {
          background-color: c.$cta-secondary;
          border-color: c.$cta-primary;
          color: c.$type-primary;
        }
      }

      &.no-hover:hover {
        background-color: transparent;
      }
    }
  }

  &.button-primary-light {
    background-color: c.$cta-secondary;
    border-color: c.$cta-secondary;
    color: c.$type-primary;

    &:not(:disabled) {
      &:hover {
        background-color: c.$cta-primary;
        border-color: c.$cta-secondary;
        color: c.$type-secondary;
      }
    }

    &.transparent {
      background-color: transparent;
      border-color: c.$cta-secondary;
      color: c.$type-secondary;

      &:not(:disabled) {
        &:hover {
          background-color: c.$cta-primary;
          border-color: c.$cta-secondary;
          color: c.$type-secondary;
        }
      }

      &.no-hover:hover {
        background-color: transparent;
      }
    }
  }

  &.button-disabled,
  &.button-disabled-dark {
    background-color: c.$cta-primary-disabled;
    color: c.$type-secondary;

    &:not(:disabled) {
      &:hover {
        cursor: auto;
      }
    }
  }

  &.button-disabled-light {
    background-color: c.$cta-secondary-disabled;
    color: c.$grey-dark-1;

    &:not(:disabled) {
      &:hover {
        cursor: auto;
      }
    }
  }

  &.button-secondary,
  &.button-secondary-light {
    background-color: c.$cta-secondary;
    border-color: c.$cta-primary;
    color: c.$primary;

    &:not(:disabled) {
      &:hover {
        background-color: c.$cta-primary;
        border-color: c.$cta-primary;
        color: c.$cta-secondary;
      }
    }

    &.transparent {
      background-color: transparent;
      border-color: c.$cta-primary;
      color: c.$type-primary;

      &:not(:disabled) {
        &:hover {
          background-color: c.$cta-primary;
          border-color: c.$cta-primary;
          color: c.$cta-secondary;
        }
      }

      &.no-hover:hover {
        background-color: transparent;
      }
    }
  }

  &.button-secondary-dark {
    background-color: c.$grey-dark-1;
    border-color: c.$cta-secondary;
    color: c.$cta-secondary;

    &:not(:disabled) {
      &:hover {
        background-color: c.$cta-secondary;
        border-color: c.$cta-secondary;
        color: c.$cta-primary;
      }
    }

    &.transparent {
      background-color: transparent;
      border-color: c.$cta-secondary;
      color: c.$cta-secondary;

      &:not(:disabled) {
        &:hover {
          background-color: c.$cta-secondary;
          border-color: c.$cta-secondary;
          color: c.$cta-primary;
        }
      }
    }
  }

  &.button-link,
  &.button-link-invert {
    width: max-content;

    &.inline {
      border: 0;
      display: inline;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &.button-link {
    color: c.$cta-primary;

    &:hover {
      color: c.$grey-dark-1;
    }
  }

  &.button-link-invert {
    color: c.$cta-secondary;

    &:hover {
      color: c.$grey-light-4;
    }
  }

  &.button-info {
    background-color: c.$cta-info;
    border-color: c.$cta-info;
    color: c.$white;

    &.transparent {
      background-color: transparent;
      color: c.$cta-info;
    }
  }

  &.button-success {
    background-color: c.$cta-success;
    border-color: c.$cta-success;
    color: c.$white;

    &.transparent {
      background-color: transparent;
      color: c.$cta-success;
    }
  }

  &.button-warning {
    background-color: c.$cta-warning;
    border-color: c.$cta-warning;
    color: c.$white;

    &.transparent {
      background-color: transparent;
      color: c.$cta-warning;
    }
  }

  &.button-error {
    background-color: c.$cta-error;
    border-color: c.$cta-error;
    color: c.$white;

    &.transparent {
      background-color: transparent;
      color: c.$cta-error;
    }
  }

  &.no-pad {
    padding-left: 0;
    padding-right: 0;
  }

  &.no-border {
    border-color: transparent;
  }

  &.icon-left {
    flex-direction: row-reverse;

    .icon,
    .icon-sm,
    .icon-lg {
      margin: 0 8px;
    }
  }

  &.icon-right {
    flex-direction: row;

    .icon,
    .icon-sm,
    .icon-lg {
      margin: 0 8px;
    }
  }

  &.icon-only {
    padding: 0.25rem;
    width: max-content;

    .icon,
    .icon-sm {
      font-size: 16px;
      height: 16x;
      margin: 0;
    }

    .icon-lg {
      font-size: 24px;
      height: 24px;
      margin: 0;
      width: 24px;
    }
  }

  &.has-icon {
    &:not(.icon-only) {
      padding: 12px 16px;
    }
  }

  .icon,
  .icon-sm {
    font-size: 16px;
    height: 16px;
  }

  .icon-lg {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  // @media #{bp.$medium-up-mq} {
  // 	font-size: 0.875rem;
  // 	letter-spacing: 0.1rem;
  // 	line-height: 1.375rem;
  // 	padding: 16px 22px;

  // 	// .icon,
  // 	// .icon-sm {
  // 	// 	font-size: 24px;
  // 	// 	height: 24px;
  // 	// 	width: 24px;
  // 	// }
  // }
}
