footer a:link {
color: #252f32;
}
  
footer a:visited {
color: #252f32;
}
  
footer a:hover {
color: #252f32;
}
  
footer a:active {
color: #252f32;
}