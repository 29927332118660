@use './partials/typography' as t;
@use 'fonts' as f;
@use 'colors' as c;
@use 'breakpoints' as bp;

.display-1,
%display-1 {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.25rem, f.$font-weight-normal, 0.221rem, 1.812rem, none);

	&--primary {
		@extend %display-1;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-1;
		color: c.$type-secondary;
	}

	&--gold {
		@extend %display-1;
		color: c.$type-gold;
	}

	&--disabled {
		@extend %display-1;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-1;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.25rem, f.$font-weight-normal, 0.591rem, 3.187rem, none);
	}
}

.display-1-heavy,
%display-1-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.25rem, f.$font-weight-demi, 0.221rem, 1.812rem, none);

	&--primary {
		@extend %display-1-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-1-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-1-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-1-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.25rem, f.$font-weight-demi, 0.591rem, 3.187rem, none);
	}
}

.display-2,
%display-2 {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.125rem, f.$font-weight-normal, 0.196rem, 1.625rem, none);

	&--primary {
		@extend %display-2;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-2;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-2;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-2;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2rem, f.$font-weight-normal, 0.35rem, 2.875rem, none);
	}
}

.display-2-heavy,
%display-2-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.125rem, f.$font-weight-demi, 0.196rem, 1.625rem, none);

	&--primary {
		@extend %display-2-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-2-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-2-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-2-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2rem, f.$font-weight-demi, 0.35rem, 2.875rem, none);
	}
}

.display-3,
%display-3 {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1rem, f.$font-weight-normal, 0.175rem, 1.437rem, none);

	&--primary {
		@extend %display-3;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-3;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-3;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-3;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.375rem, f.$font-weight-normal, 0.24rem, 2rem, none);
	}
}

.display-3-heavy,
%display-3-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1rem, f.$font-weight-demi, 0.175rem, 1.437rem, none);

	&--primary {
		@extend %display-3-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-3-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-3-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-3-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1.375rem, f.$font-weight-demi, 0.24rem, 2rem, none);
	}
}

.display-4,
%display-4 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.875rem, f.$font-weight-normal, 0.155rem, 1.25rem, none);

	&--primary {
		@extend %display-4;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-4;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-4;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-4;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1rem, f.$font-weight-normal, 0.175rem, 1.437rem, none);
	}
}

.display-4-heavy,
%display-4-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.875rem, f.$font-weight-demi, 0.155rem, 1.25rem, none);

	&--primary {
		@extend %display-4-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-4-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-4-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-4-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1rem, f.$font-weight-demi, 0.175rem, 1.437rem, none);
	}
}

.display-5,
%display-5 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.625rem, f.$font-weight-normal, 0.281rem, 2.312rem, none);

	&--primary {
		@extend %display-5;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-5;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-5;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-5;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.75rem, f.$font-weight-normal, 0.481rem, 4rem, none);
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 4rem, f.$font-weight-normal, 0.7rem, 5.75rem, none);
	}
}

.display-5-heavy,
%display-5-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.625rem, f.$font-weight-demi, 0.281rem, 2.312rem, none);

	&--primary {
		@extend %display-5-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-5-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-5-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-5-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.75rem, f.$font-weight-demi, 0.481rem, 4rem, none);
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 4rem, f.$font-weight-demi, 0.7rem, 5.75rem, none);
	}
}

.display-6,
%display-6 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.437rem, f.$font-weight-normal, 0.251rem, 2.062rem, none);

	&--primary {
		@extend %display-6;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-6;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-6;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-6;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.25rem, f.$font-weight-normal, 0.396rem, 3.375rem, none);
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.75rem, f.$font-weight-normal, 0.481rem, 3.937rem, none);
	}
}

.display-6-heavy,
%display-6-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.437rem, f.$font-weight-demi, 0.251rem, 2.062rem, none);

	&--primary {
		@extend %display-6-heavy ;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %display-6-heavy ;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %display-6-heavy ;
		color: c.$type-disabled;
	}

	&--white {
		@extend %display-6-heavy ;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.25rem, f.$font-weight-demi, 0.396rem, 3.375rem, none);
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic-wide, 2.75rem, f.$font-weight-demi, 0.481rem, 3.937rem, none);
	}
}

.headline-1,
%headline-1 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.25rem, f.$font-weight-lighter, 0, 2.125rem, none);

	&--primary {
		@extend %headline-1;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-1;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-1;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-1;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 2.25rem, f.$font-weight-lighter, 0, 3.375rem, none);
	}
}

.headline-1-heavy,
%headline-1-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.25rem, f.$font-weight-book, 0, 2.125rem, none);

	&--primary {
		@extend %headline-1-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-1-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-1-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-1-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 2.25rem, f.$font-weight-book, 0, 3.375rem, none);
	}
}

.headline-2,
%headline-2 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-lighter, 0.218rem, 1.625rem, none);

	&--primary {
		@extend %headline-2;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-2;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-2;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-2;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.25rem, f.$font-weight-lighter, 0.25rem, 1.875rem, none);
	}
}

.headline-2-heavy,
%headline-2-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-book, 0.218rem, 1.625rem, none);

	&--primary {
		@extend %headline-2-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-2-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-2-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-2-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.25rem, f.$font-weight-book, 0.25rem, 1.875rem, none);
	}
}

.headline-3,
%headline-3 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-lighter, 0.187rem, 1.562rem, none);

	&--primary {
		@extend %headline-3;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-3;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-3;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-3;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-lighter, 0.218rem, 1.75rem, none);
	}
}

.headline-3-heavy,
%headline-3-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-book, 0.187rem, 1.562rem, none);

	&--primary {
		@extend %headline-3-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-3-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-3-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-3-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-book, 0.218rem, 1.75rem, none);
	}
}

.headline-4,
%headline-4 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.875rem, f.$font-weight-lighter, 0.062rem, 1.437rem, none);

	&--primary {
		@extend %headline-4;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-4;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-4;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-4;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-lighter, 0.071rem, 1.625rem, none);
	}
}

.headline-4-heavy,
%headline-4-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.875rem, f.$font-weight-book, 0.062rem, 1.437rem, none);

	&--primary {
		@extend %headline-4-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-4-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-4-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-4-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-book, 0.071rem, 1.625rem, none);
	}
}

/** Not in style guide, do not use **/
.headline-5,
%headline-5 {
	@include t.typography(null, f.$font-family-cadillac-gothic, f.$base-font, f.$font-weight-bold, -0.5px, 1.2, none);

	&--primary {
		@extend %headline-5;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-5;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-5;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-5;
		color: c.$type-white;
	}
}

/** Not in style guide, do not use **/
.headline-6,
%headline-6 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.2rem, f.$font-weight-bold, -0.25px, 1.2, none);

	&--primary {
		@extend %headline-6;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %headline-6;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %headline-6;
		color: c.$type-disabled;
	}

	&--white {
		@extend %headline-6;
		color: c.$type-white;
	}
}

/** Not in style guide, do not use **/
.block-quote-lg,
%block-quote-lg {
	@include t.typography(null, f.$font-family-cadillac-gothic, 2.4rem, f.$font-weight-normal, -0.5px, 1.333, none);

	&--primary {
		@extend %block-quote-lg;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %block-quote-lg;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %block-quote-lg;
		color: c.$type-disabled;
	}

	&--white {
		@extend %block-quote-lg;
		color: c.$type-white;
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 2.8rem, f.$font-weight-normal, -0.5px, 1.333, none);
	}
}

/** Not in style guide, do not use **/
.block-quote-md,
%block-quote-md {
	@include t.typography(null, f.$font-family-cadillac-gothic, 2rem, f.$font-weight-normal, -0.5px, 1.4, none);

	&--primary {
		@extend %block-quote-md;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %block-quote-md;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %block-quote-md;
		color: c.$type-disabled;
	}

	&--white {
		@extend %block-quote-md;
		color: c.$type-white;
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.22 2rem, f.$font-weight-normal, -0.5px, 1.4, none);
	}
}

/** Not in style guide, do not use **/
.block-quote-sm,
%block-quote-sm {
	@include t.typography(null, f.$font-family-cadillac-gothic, f.$base-font, f.$font-weight-normal, -0.3px, 1.4, none);

	&--primary {
		@extend %block-quote-sm;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %block-quote-sm;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %block-quote-sm;
		color: c.$type-disabled;
	}

	&--white {
		@extend %block-quote-sm;
		color: c.$type-white;
	}

	@media #{bp.$large-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, f.$base-font, f.$font-weight-normal, -0.3px, 1.4, none);
	}
}

.body1,
.paragraph-1,
%paragraph-1 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-lighter, 0.04rem, 1.812rem, none);

	&--primary {
		@extend %paragraph-1;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-1;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-1;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-1;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-lighter, 0.046rem, 2rem, none);
	}
}

.body1-heavy,
.paragraph-1-heavy,
%paragraph-1-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1rem, f.$font-weight-book, 0.04rem, 1.812rem, none);

	&--primary {
		@extend %paragraph-1-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-1-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-1-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-1-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 1.125rem, f.$font-weight-book, 0.046rem, 2rem, none);
	}
}

.body2,
.paragraph-2,
%paragraph-2 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.812rem, f.$font-weight-lighter, 0.037rem, 1.562rem, none);

	&--primary {
		@extend %paragraph-2;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-2;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-2;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-2;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 0.937rem, f.$font-weight-lighter, 0.04rem, 1.687rem, none);
	}
}

.body2-heavy,
.paragraph-2-heavy,
%paragraph-2-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.812rem, f.$font-weight-book, 0.037rem, 1.562rem, none);

	&--primary {
		@extend %paragraph-2-heavy ;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-2-heavy ;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-2-heavy ;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-2-heavy ;
		color: c.$type-white;
	}


	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 0.937rem, f.$font-weight-book, 0.04rem, 1.687rem, none);
	}
}

/** Not in style guide, do not use **/
.paragraph-1-alt,
%paragraph-1-alt {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, f.$base-font, f.$font-weight-lighter, 0, 1, uppercase);

	&--primary {
		@extend %paragraph-1-alt;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-1-alt;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-1-alt;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-1-alt;
		color: c.$type-white;
	}
}

/** Not in style guide, do not use **/
.paragraph-2-alt,
%paragraph-2-alt {
	@include t.typography(null, f.$font-family-cadillac-gothic, f.$base-font, f.$font-weight-bold, 0, 1.2, none);

	&--primary {
		@extend %paragraph-2-alt;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-2-alt;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-2-alt;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-2-alt;
		color: c.$type-white;
	}
}

.body3,
.paragraph-3,
%paragraph-3 {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.625rem, f.$font-weight-lighter, 0.034rem, 1.25rem, none);

	&--primary {
		@extend %paragraph-3;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-3;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-3;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-3;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 0.75rem, f.$font-weight-lighter, 0.037rem, 1.375rem, none);
	}
}

.body3-heavy,
.paragraph-3-heavy,
%paragraph-3-heavy {
	@include t.typography(null, f.$font-family-cadillac-gothic, 0.625rem, f.$font-weight-book, 0.034rem, 1.25rem, none);

	&--primary {
		@extend %paragraph-3-heavy;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %paragraph-3-heavy;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %paragraph-3-heavy;
		color: c.$type-disabled;
	}

	&--white {
		@extend %paragraph-3-heavy;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, 0.75rem, f.$font-weight-book, 0.037rem, 1.375rem, none);
	}
}

/** Not in style guide, do not use **/
.desc,
.small,
%small {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.4rem, f.$font-weight-book, 0, 1.2, none);

	&--primary {
		@extend %small;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %small;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %small;
		color: c.$type-disabled;
	}

	&--white {
		@extend %small;
		color: c.$type-white;
	}
}

/** Not in style guide, do not use **/
.desc-alt,
.small-alt,
%small-alt {
	@include t.typography(null, f.$font-family-cadillac-gothic, 1.2rem, f.$font-weight-bold, 0.25px, 1.2, uppercase);

	&--primary {
		@extend %small-alt;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %small-alt;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %small-alt;
		color: c.$type-disabled;
	}

	&--white {
		@extend %small-alt;
		color: c.$type-white;
	}
}

/** Not in style guide, do not use **/
.caption,
%caption {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 1rem, f.$font-weight-normal, 0.3px, 1.3, none);

	&--primary {
		@extend %caption;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %caption;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %caption;
		color: c.$type-disabled;
	}

	&--white {
		@extend %caption;
		color: c.$type-white;
	}
}

/** Not in style guide **/
.caption-alt,
%caption-alt {
	@include t.typography(null, f.$font-family-cadillac-gothic-wide, 0.9rem, f.$font-weight-demi, 0.5px, 1, uppercase);

	&--primary {
		@extend %caption-alt;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %caption-alt;
		color: c.$type-secondary;
	}

	&--disabled {
		@extend %caption-alt;
		color: c.$type-disabled;
	}

	&--white {
		@extend %caption-alt;
		color: c.$type-white;
	}
}

/** Added for EV Education Showroom **/
.label-1,
%label-1 {
	@include t.typography(null, f.$font-family-cadillac-gothic, .75rem, f.$font-weight-medium, .086rem, 1rem, uppercase);

	&--primary {
		@extend %label-1;
		color: c.$type-primary;
	}

	&--secondary {
		@extend %label-1;
		color: c.$type-secondary;
	}

	&--gold {
		@extend %label-1;
		color: c.$type-gold;
	}

	&--disabled {
		@extend %label-1;
		color: c.$type-disabled;
	}

	&--white {
		@extend %label-1;
		color: c.$type-white;
	}

	@media #{bp.$medium-up-mq} {
		@include t.typography(null, f.$font-family-cadillac-gothic, .875rem, f.$font-weight-medium, .1rem, 1.375rem, uppercase);
	}
}
